/* eslint-disable no-console */
/* eslint-disable no-prototype-builtins */

import { Image, Flex, Text, Button } from '@chakra-ui/react';

import SpotifyLogo from '../../Assets/Images/Full_Logo_Black_CMYK.svg';

export default function ConnectSpotifyButton({
  hasSpotifyConnected,
  onOpenSpotifyInfoModal,
  onOpenSpotifyDrawer,
}) {
  return (
    <Flex
      direction="column"
      bg="#fffff2"
      border="2px solid black"
      borderRadius="16px"
      boxShadow="2px 2px 0px 0px #000"
      p={3}
      width="99%"
    >
      <Flex alignItems="center" mb={5} pl="7px" pt="7px">
        <Image src={SpotifyLogo} width="140px" />
      </Flex>

      <Flex justifyContent="flex-end" alignItems="center">
        <Text fontFamily="DM Sans" fontSize="12px" lineHeight={1.1} mr={2}>
          {!hasSpotifyConnected && (
            <a
              style={{ textDecoration: 'underline' }}
              href="https://www.cruwi.com/creators-conditions"
              target="_blank"
              rel="noopener noreferrer"
            >
              Términos y Condiciones
            </a>
          )}
        </Text>
        {hasSpotifyConnected ? (
          <Button
            variant="solid"
            size="xs"
            colorScheme="gray"
            paddingRight="18px"
            paddingLeft="18px"
            disabled
            onClick={onOpenSpotifyDrawer}
          >
            Ver mis datos
          </Button>
        ) : (
          <Button
            _hover={{ bg: '#edf2f7' }}
            variant="outline"
            size="xs"
            border="1px solid black"
            paddingRight="12px"
            paddingLeft="12px"
            onClick={onOpenSpotifyInfoModal}
          >
            Conectar
          </Button>
        )}
      </Flex>
    </Flex>
  );
}
