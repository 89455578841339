/* eslint-disable no-console */

import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  Text,
  Flex,
  Image,
  Heading,
  useDisclosure,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  ModalHeader,
  useMediaQuery,
} from '@chakra-ui/react';

import { FiX } from 'react-icons/fi';

import {
  useDisconnectSpotifyMutation,
  useGetUserSpotifyDataQuery,
} from '../../Redux/features/auth/authApiSlice';

import LoadingChallenges from '../LoadingChallenges/LoadingChallenges';

export default function SpotifyDrawer({ spotifyInfo, isOpenSpotifyDrawer, onCloseSpotifyDrawer }) {
  const { data: spotifyData, isLoading: isSpotifyDataLoading } = useGetUserSpotifyDataQuery();
  const [disconnectSpotify, { isLoading }] = useDisconnectSpotifyMutation();

  const {
    isOpen: isOpenConfirmSpotifyDisconnection,
    onOpen: onOpenConfirmSpotifyDisconnection,
    onClose: onCloseConfirmSpotifyDisconnection,
  } = useDisclosure();

  // Cambio de tamaño para responsive
  const [isLargerThan768] = useMediaQuery('(min-width: 768px)');

  // Mostramos algo mientras carga
  if (isSpotifyDataLoading) {
    return <LoadingChallenges />;
  }

  const handleDisconnectSpotify = async () => {
    await disconnectSpotify().unwrap();
    onCloseConfirmSpotifyDisconnection();
    onCloseSpotifyDrawer();
  };

  const topArtists = spotifyData?.items;

  return (
    <Drawer isOpen={isOpenSpotifyDrawer} placement="right" onClose={onCloseSpotifyDrawer} size="sm">
      <DrawerOverlay />
      <DrawerContent fontFamily="DM Sans">
        <DrawerHeader
          bg="#FFFFF2"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          height="55px"
          borderBottomWidth="1px"
        >
          <Box />
          <Box>
            <FiX cursor="pointer" size="20px" onClick={onCloseSpotifyDrawer} />
          </Box>
        </DrawerHeader>

        <DrawerBody bg="#FFFFF2" padding={5}>
          <Box mb={8}>
            <Heading fontFamily="DM Sans" size="lg" mb={0}>
              Tus gustos musicales
            </Heading>
            <Flex align="center" gap={2} mb={6}>
              <Text>según tu cuenta: </Text>
              <Text fontWeight="medium">{spotifyInfo?.displayName}</Text>
            </Flex>

            {/* Favorite Artists Section */}
            <Box mb={8}>
              <Box borderWidth={1} borderStyle="dashed" borderRadius="lg" p={4}>
                <Heading fontFamily="DM Sans" size="md" mb={4}>
                  Tus artistas fav
                </Heading>
                <Box>
                  {topArtists &&
                    topArtists.length > 0 &&
                    topArtists.map((a) => (
                      <Box p={2} width="100%" key={a.id}>
                        <Flex alignItems="center">
                          <Image
                            borderRadius="2px"
                            src={a?.images?.[0].url}
                            width="70px"
                            height="70px"
                          />
                          <Text ml={4}>{a.name}</Text>
                        </Flex>
                      </Box>
                    ))}
                </Box>
              </Box>
            </Box>

            <Box width="100%">
              <Button
                display="block"
                margin="0 auto"
                colorScheme="red"
                variant="link"
                onClick={onOpenConfirmSpotifyDisconnection}
              >
                Desconectar de Spotify
              </Button>

              {/* Modal confirmar acción de desconexión */}
              <Modal
                size={isLargerThan768 ? 'lg' : 'lg'}
                isOpen={isOpenConfirmSpotifyDisconnection}
                onClose={onCloseConfirmSpotifyDisconnection}
                isCentered
                scrollBehavior={isLargerThan768 ? 'inside' : 'inside'}
              >
                <ModalOverlay />
                <ModalContent
                  border="2px solid #EBD0FF"
                  borderRadius={isLargerThan768 ? '24px' : '24px'}
                  m={isLargerThan768 ? 5 : 5}
                  maxHeight={isLargerThan768 ? '90%' : 'calc(100vh - 48px)'}
                  bg="#feffef"
                >
                  <ModalHeader
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    borderBottomWidth="1px"
                    fontFamily="DM Sans"
                  >
                    <Text fontSize="16px">Desconectar cuenta</Text>
                    <Box cursor="pointer">
                      <FiX size="20px" onClick={onCloseConfirmSpotifyDisconnection} />
                    </Box>
                  </ModalHeader>
                  <ModalBody fontFamily="DM Sans" padding={0}>
                    <Flex p={5} flexDir="column" justifyContent="space-between">
                      <Box>
                        <Box>
                          <Text mb={2} fontSize="16px" fontWeight="bold" lineHeight={1.3}>
                            ¿Estás seguro de que quieres desconectar tu cuenta de Spotify?
                          </Text>
                          <Text mb="50px" lineHeight={1.3}>
                            Perderás el acceso prioritario a muchos de los retos musicales.
                          </Text>
                        </Box>

                        <Box>
                          <Button
                            onClick={handleDisconnectSpotify}
                            width="100%"
                            paddingRight="12px"
                            paddingLeft="12px"
                            border="2px solid black"
                            boxShadow="1px 1px 0px 0px #000"
                            borderRadius="16px"
                            bg="#EBD0FF"
                            color="black"
                            disabled={isLoading}
                          >
                            Desconectar
                          </Button>
                        </Box>
                      </Box>
                    </Flex>
                  </ModalBody>
                </ModalContent>
              </Modal>
            </Box>

            {/* Section for link */}
            <Box textAlign="center" mt="30px" width="100%">
              <Text fontSize="14px" fontFamily="DM Sans">
                Cualquier duda lea nuestros{' '}
                <a
                  style={{ textDecoration: 'underline' }}
                  href="https://www.cruwi.com/creators-conditions"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Términos y Condiciones
                </a>{' '}
                y{' '}
                <a
                  style={{ textDecoration: 'underline' }}
                  href="https://www.cruwi.com/privacy-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Política de Privacidad
                </a>
                .
              </Text>
            </Box>
          </Box>
        </DrawerBody>

        <DrawerFooter bg="#FFFFF2" padding={2} borderTopWidth="1px">
          <Button
            border="2px solid black"
            boxShadow="1px 1px 0px 0px #000"
            borderRadius="16px"
            bg="#FFFFF2"
            color="black"
            size="sm"
            onClick={onCloseSpotifyDrawer}
          >
            Cerrar
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}
