/* eslint-disable no-console */
/* eslint-disable no-prototype-builtins */

import React from 'react';
import { Box, Flex, Text, Button } from '@chakra-ui/react';

import PayPalLogo from '../../Assets/Images/paypal-logo.png';
import { useLazyGetPaypalAuthUrlQuery } from '../../Redux/features/auth/authApiSlice';

export default function ConnectPayPalButton({ hasPayPalConnected }) {
  const [getPaypalAuthUrl] = useLazyGetPaypalAuthUrlQuery();

  const onClick = async () => {
    const { data: paypalAuthUrl, isError } = await getPaypalAuthUrl();
    if (isError) {
      console.error('There was an error trying to log in with Paypal');
      return;
    }
    window.location.replace(paypalAuthUrl.data);
  };

  return (
    <Flex
      direction="column"
      bg="#fffff2"
      border="2px solid black"
      borderRadius="16px"
      boxShadow="2px 2px 0px 0px #000"
      p={3}
      width="99%"
    >
      <Flex alignItems="center" mb={5} pl="7px" pt="7px">
        <Box
          width="40px"
          borderRadius="50%"
          height="40px"
          p={5}
          border="1px solid black"
          backgroundPosition="center"
          backgroundSize="20px"
          backgroundRepeat="no-repeat"
          backgroundImage={PayPalLogo}
          marginRight="10px"
        />
        <Box>
          <Text fontFamily="DM Sans" fontSize="20px" fontWeight="black">
            PayPal {hasPayPalConnected && '✅'}
          </Text>
        </Box>
      </Flex>

      <Flex justifyContent="flex-end" alignItems="center">
        <Text fontFamily="DM Sans" fontSize="12px" lineHeight={1.1} mr={2}>
          {hasPayPalConnected
            ? 'Tu próxima retirada se realizará por este método'
            : 'Aún no has configurado este método'}
        </Text>
        {hasPayPalConnected ? (
          <Button
            variant="solid"
            size="xs"
            colorScheme="gray"
            paddingRight="18px"
            paddingLeft="18px"
            disabled
          >
            Configurado
          </Button>
        ) : (
          <Button
            _hover={{ bg: '#edf2f7' }}
            variant="outline"
            size="xs"
            border="1px solid black"
            paddingRight="12px"
            paddingLeft="12px"
            onClick={onClick}
          >
            Configurar
          </Button>
        )}
      </Flex>
    </Flex>
  );
}
