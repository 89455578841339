/* eslint-disable no-console */

import {
  Box,
  Heading,
  Text,
  Badge,
  useDisclosure,
  Modal,
  useMediaQuery,
  ModalBody,
  ModalContent,
  Button,
  ModalOverlay,
  Flex,
  ModalHeader,
  Image,
} from '@chakra-ui/react';

import { FiX } from 'react-icons/fi';

import { useLazySendToSpotifyAuthQuery } from '../../Redux/features/auth/authApiSlice';

import ConnectSpotifyButton from '../ConnectSpotifyButton/ConnectSpotifyButton';
import SpotifyDrawer from './SpotifyDrawer';

import SpotifyLogo from '../../Assets/Images/Full_Logo_Black_CMYK.svg';

export default function SpotifyConnect({ userData }) {
  const { spotifyInfo } = userData;
  const hasSpotifyConnected = spotifyInfo?.accessToken !== undefined;

  // Abre Modal de info Spotify usuario
  const {
    isOpen: isOpenSpotifyInfoModal,
    onOpen: onOpenSpotifyInfoModal,
    onClose: onCloseSpotifyInfoModal,
  } = useDisclosure();

  // Abre Drawer de info Spotify usuario
  const {
    isOpen: isOpenSpotifyDrawer,
    onOpen: onOpenSpotifyDrawer,
    onClose: onCloseSpotifyDrawer,
  } = useDisclosure();

  // Cambio de tamaño para responsive
  const [isLargerThan768] = useMediaQuery('(min-width: 768px)');

  // Funciones para enviar al usuario a conectar su Spotify
  const [sendToSpotify] = useLazySendToSpotifyAuthQuery();
  const handleClickConnectSpotify = async () => {
    try {
      const spotifyApiResult = await sendToSpotify();
      if (spotifyApiResult.isSuccess) {
        window.location.replace(spotifyApiResult.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box maxWidth="700px" margin="0 auto">
      <Box mt={5}>
        <Heading fontSize="24px" fontFamily="DM Sans">
          Conecta tu Spotify
          <Badge ml={1} bg="#EBD0FF">
            NUEVO
          </Badge>
        </Heading>
        <Text fontStyle="italic" opacity="0.8" fontSize="12px" maxW={400}>
          ¡Podrás acceder a colaboraciones exclusivas!
        </Text>
      </Box>
      <Box display="flex" mt={5} overflowX="auto" pb={7}>
        <ConnectSpotifyButton
          hasSpotifyConnected={hasSpotifyConnected}
          onOpenSpotifyInfoModal={onOpenSpotifyInfoModal}
          onOpenSpotifyDrawer={onOpenSpotifyDrawer}
        />
      </Box>

      {/* Modal Spotify conectado información */}
      <Modal
        size={isLargerThan768 ? 'lg' : 'lg'}
        isOpen={isOpenSpotifyInfoModal}
        onClose={onCloseSpotifyInfoModal}
        isCentered
        scrollBehavior={isLargerThan768 ? 'inside' : 'inside'}
      >
        <ModalOverlay />
        <ModalContent
          border="2px solid #EBD0FF"
          borderRadius={isLargerThan768 ? '24px' : '24px'}
          m={isLargerThan768 ? 5 : 5}
          maxHeight={isLargerThan768 ? '90%' : 'calc(100vh - 48px)'}
          bg="#feffef"
        >
          <ModalHeader
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            borderBottomWidth="1px"
          >
            <Text fontSize="16px"> </Text>
            <Box cursor="pointer">
              <FiX size="20px" onClick={onCloseSpotifyInfoModal} />
            </Box>
          </ModalHeader>
          <ModalBody padding={0}>
            <Flex p={5} flexDir="column" justifyContent="space-between">
              <Box>
                {/* Textos y condiciones */}
                <Box>
                  {/* Intro */}
                  <Text mb={0} fontSize={24} fontFamily="DM Sans" fontWeight="bold">
                    Conecta tu Spotify
                  </Text>
                  <Text fontFamily="DM Sans">Y colabora con tus artistas favoritos</Text>
                  <Box m="19px">
                    <Image margin="0 auto" src={SpotifyLogo} width="140px" />
                  </Box>

                  <Text fontFamily="DM Sans" mb={6}>
                    Ahora en CRUWI puedes acceder a colaboraciones exclusivas con artistas
                    conectando tu Spotify. Usaremos los datos de tu cuenta de Spotify para saber más
                    acerca de tus gustos musicales y poder ofrecerte retos y colaboraciones con
                    artistas más precisos.
                  </Text>
                </Box>

                {/* Botón */}
                <Box>
                  <Button
                    onClick={handleClickConnectSpotify}
                    width="100%"
                    fontFamily="DM Sans"
                    paddingRight="12px"
                    paddingLeft="12px"
                    border="2px solid black"
                    boxShadow="1px 1px 0px 0px #000"
                    borderRadius="16px"
                    bg="#EBD0FF"
                    color="black"
                  >
                    Conectar
                  </Button>
                </Box>
              </Box>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* Drawer con la info de Spotify */}
      <SpotifyDrawer
        spotifyInfo={spotifyInfo}
        isOpenSpotifyDrawer={isOpenSpotifyDrawer}
        onCloseSpotifyDrawer={onCloseSpotifyDrawer}
      />
    </Box>
  );
}
